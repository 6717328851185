class HttpClient {
    async fetchData(url, method, body, headers) {
        var result = { status: 200, message: "Success", data: {} };
        try {
            if (method !== "GET") { if (body === undefined) { body = {}; } } else { body = undefined; }
            if (headers === undefined && method !== "UPLOAD") {
                headers = { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*' };
            }
            var requestOptions = { method: method, headers: headers, body: (body !== undefined) ? JSON.stringify(body) : "" };
            if(method==="GET"){
                requestOptions = { method: method, headers: headers };
            }
            if (method === "UPLOAD") {
                method = "POST";
                requestOptions = { method: method, headers: headers, body: (body !== undefined) ? body : "" };
            }
            var apiResponse = await fetch(url, requestOptions);
            if (apiResponse.status === 200) {
                var response = await apiResponse.json();
                result = { status: 200, data: response, message: "Success" };
            } else {
                result = { status: 400, data: [], message: "API not available" };
            }
        } catch (error) {
            result = { status: 400, data: [], message: error.message };
        }
        return result;
    }
    async fetchBackendData(url, method, body, headers) {
        var result = { status: 200, message: "Success", data: {} };
        try {
            var response = await this.fetchData(url, method, body, headers);
            if (response.status === 200 && response.data !== undefined && response.data.status !== undefined) {
                result = response.data;
            } else {
                result = response;
            }
        } catch (error) {
            result = { status: 400, data: [], message: error.message };
        }
        return result;
    }
    async file_upload(file, url, type, extra) {
        var result = { status: 200, message: "Success", data: {} };
        try {
            if (type === 1) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileName", "file_upload");
                var headers = { 'Access-Control-Allow-Origin': '*', 'Accept': 'application/json' };
                result = await this.fetchBackendData(url, "UPLOAD", formData, headers);
            } else {
                result = { status: 400, data: [], message: "Uploaded method not available" };
            }
        } catch (error) {
            result = { status: 400, data: [], message: error.message };
        }
        return result;
    }
    async session_validation(config_data) {
        try {
            var url = "/admin/login";
            var loginUserID = window.sessionStorage.getItem("user_id");
            var loginToken = window.sessionStorage.getItem("token");
            if (loginUserID === undefined || loginUserID === "" || loginToken === undefined || loginToken === "" || config_data === undefined) {
            } else if (loginToken !== undefined && loginToken !== "") {
                let requestObject = { token: loginToken, user_id: loginUserID };
                var base_url = config_data.back_end_base_url;
                var api_name = config_data.api_details.token_validation;
                let response = await this.fetchBackendData(base_url + api_name, "POST", requestObject);
                if (response.status === 200 && response.data !== undefined) {
                    if (response.data.refresh_Token !== undefined) {
                        window.sessionStorage.setItem("token", response.data.refresh_Token);
                        if (response.data.decoded !== undefined && response.data.decoded !== "") {
                            window.sessionStorage.setItem("token_details", JSON.stringify(response.data.decoded));
                        }
                        url = "";
                    }
                }
            }
            return url;
        } catch (error) {
            console.error(error)
        }

    }
    async get_Session_Data(request_object) {
        var session_data = undefined;
        try {
            var user_id = window.sessionStorage.getItem("user_id");
            var token = window.sessionStorage.getItem("token");
            var token_data = window.sessionStorage.getItem("token_details");
            var full_name = window.sessionStorage.getItem("user_full_name");
            var user_details = window.sessionStorage.getItem("user_details");
            if (user_id !== undefined && token !== undefined && token_data !== undefined && token_data !== "" && full_name !== undefined && full_name !== "" && user_details !== undefined && user_details !== "") {
                token_data = JSON.parse(token_data);
                user_details = JSON.parse(user_details);
                var page_action={read:false,write:false,export:false};
                var role_privilege_group=window.sessionStorage.getItem("role_privilege_group");
                var side_bar_list=window.sessionStorage.getItem("side_bar_list");
                
                if(role_privilege_group!==undefined&&role_privilege_group!==null&&role_privilege_group!==""){
                    role_privilege_group=JSON.parse(role_privilege_group);
                }else{
                    role_privilege_group={};
                }
                if(side_bar_list!==undefined&&side_bar_list!==""){
                    side_bar_list=JSON.parse(side_bar_list);
                }else{
                    side_bar_list=[];
                }
                if(request_object!==undefined&&request_object.page_key!==undefined){
                    page_action=role_privilege_group[request_object.page_key];
                }
                if(page_action!==undefined || request_object.page_key==="Session Handling"){
                    session_data = {
                        user_id, token, token_data, full_name, user_details,page_action,side_bar_list
                    };
                }else{
                    this.autoRedirectionCheck();
                }
                
            }
        } catch (error) {
            console.error(error);
        }
        return session_data;
    }
    async autoRedirectionCheck(){
        try {
            var user_details = window.sessionStorage.getItem("user_details");
            var url="/admin/login";
            if(user_details !== undefined && user_details !== ""){
                user_details = JSON.parse(user_details);
                if(user_details.user_type===2){
                    url="/login";
                }
            }
            this.log_out_option(url);
        } catch (error) {
            console.error(error);
        }
    }
    async login_user_session(response_data) {
        try {
            if (response_data !== undefined && response_data.user !== undefined && response_data.token !== undefined && response_data.token_data !== undefined) {
                var user_details = response_data.user;
                var user_id = user_details.user_id;
                var full_name = user_details.first_name + " " + user_details.last_name;
                var token = response_data.token;
                var token_data = response_data.token_data;
                var role_privilege=user_details.role_privilege;
                var side_bar_list=user_details.side_bar_list;
                if(role_privilege===undefined){
                    role_privilege=[];
                }
                if((role_privilege.length===0)&&user_details.user_type===1){

                }else{
                    var role_group={};
                    for(var i=0;i<role_privilege.length;i++){
                        var page_row_object=role_privilege[i];
                        var group_row_key=page_row_object.group_id+"_"+page_row_object.page_id;
                        if(role_group[group_row_key]===undefined){
                            role_group[group_row_key]=page_row_object;
                        }
                    }
                    window.sessionStorage.setItem("user_id", user_id);
                    window.sessionStorage.setItem("role_privilege_list", JSON.stringify(role_privilege));
                    window.sessionStorage.setItem("role_privilege_group", JSON.stringify(role_group));
                    window.sessionStorage.setItem("side_bar_list", JSON.stringify(side_bar_list));
                    window.sessionStorage.setItem("token", token);
                    window.sessionStorage.setItem("token_details", JSON.stringify(token_data));
                    window.sessionStorage.setItem("user_full_name", full_name);
                    window.sessionStorage.setItem("user_details", JSON.stringify(user_details));
                }
                

            }
        } catch (error) {
            console.error(error);
        }
    }
    async getLanguageSession(){
        var language=window.sessionStorage.getItem("language");
        if(language!=undefined&&language!=""){
            language=JSON.parse(language);
        }else{
            language={ id: 1, name: "English", code: "en", isRtl: false };
        }
        return language;
    }
    async setLanguageSession(language){
        if(language!=undefined&&language.id!=undefined){
            window.sessionStorage.setItem("language",JSON.stringify(language));
        }
    }
    async remove_user_session() {
        try {
            window.sessionStorage.removeItem("role_privilege_list");
            window.sessionStorage.removeItem("role_privilege_group");
            window.sessionStorage.removeItem("side_bar_list");
            window.sessionStorage.removeItem("user_id");
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("token_details");
            window.sessionStorage.removeItem("user_full_name");
            window.sessionStorage.removeItem("user_details");
        } catch (error) {
            console.error(error);
        }
    }
    async log_out_option(url) {
        this.remove_user_session();
        if (url !== undefined && url !== ""&&typeof url === 'string') {
            window.location.href = url;
        } else {
            window.location.href = "/admin/login";
        }

    }
    async login_redirection(request_Object) {
        try {
            var redirection_url="/admin/login";
            var side_bar_list=window.sessionStorage.getItem("side_bar_list");
            if(side_bar_list!==undefined&&side_bar_list!==""){
                side_bar_list=JSON.parse(side_bar_list);
                var new_url="";
                for(var i=0;i<side_bar_list.length;i++){
                    var sidebar_main_object=side_bar_list[i];
                    var sub_items=sidebar_main_object.items;
                    if(sub_items!==undefined && sub_items.length>0){
                        for(var j=0;j<sub_items.length;j++){
                            var sidebar_sub_object=sub_items[j];
                            if(sidebar_sub_object.path!==undefined&&sidebar_sub_object.path!==""){
                                new_url=sidebar_sub_object.path;
                                break;
                            }
                        }
                        if(new_url!==""){
                            break;
                        }
                    }else{
                        if(sidebar_main_object.path!==undefined&&sidebar_main_object.path!==""){
                            new_url=sidebar_main_object.path;
                            break;
                        }
                    }
                }
                if(new_url!==""){
                    redirection_url=new_url;
                }
            }
            window.location.href = redirection_url;
        } catch (error) {
            console.error(error);
        }
    }
    async usersiteVisitData(default_config,site_visit_type) {
        try {
            var all_paths={
                "/":"Home Page",
                "/home":"Home Page",
                "/holding/home":"Home Page",
                "/holding/about-us":"About US",
            };

            var vistor = window.sessionStorage.getItem("vistor");
            var pathname=window.location.pathname;
            if (vistor === undefined || vistor===null) {
                vistor={};
            }else{
                vistor=JSON.parse(vistor);
            }
            var page_name=all_paths[pathname];
            if (vistor[page_name] === undefined || vistor[page_name]===null) {
                this.fetchBackendData("http://ip-api.com/json", "GET", undefined, undefined).then(response => {
                    var request_page = window.location.href;
                    vistor[page_name]="true";
                    request_page = page_name;
                    // if (request_page.includes('/#/')) {
                    //     if (request_page.split('/#/').length > 1) {
                    //         request_page = request_page.split('/#/')[1];
                    //     }
                    //     if (request_page === "") {
                    //         request_page = "Home Page";
                    //     }
                    // } else {
                    //     request_page = "Home Page";
                    // }
                    if (response !== undefined && response.query !== undefined) {
                        var field_data = {
                            site_visit_ip_address: response.query,
                            site_visit_city: response.city,
                            site_visit_country_name: response.country,
                            site_visit_country_code: response.countryCode,
                            site_visit_region_name: response.regionName,
                            site_visit_region_code: response.region,
                            site_visit_lat: ""+response.lat,
                            site_visit_lon: ""+response.lon,
                            site_visit_timezone: response.timezone,
                            site_visit_type: site_visit_type,
                            site_visit_request_page: request_page
                        };
                        var requestObject = { data: { field_data: field_data } };
                        this.fetchBackendData(default_config.back_end_base_url + default_config.api_details.site_visitor, "POST", requestObject,undefined).then(response2 => {
                            if (response2.status === 200) {
                                window.sessionStorage.setItem("vistor", JSON.stringify(vistor));
                            }
                        });
                    }else{
                        console.error(response);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }
    async waitReadyState(){
        console.log("Wait")
        return true;
    }
    async dynamicScriptExcluded(all_script,id_prefix){
        try {
            for (var i = 0; i < all_script.length; i++) {
                var document_id = id_prefix+"Dynamic_" + i;
                var script = document.getElementById(document_id);
                if (script !== null && script !== undefined && script.parentNode !== undefined &&script.parentNode !== null) {
                    script.parentNode.removeChild(script);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    async delayFunction(ms){
        new Promise(
            resolve => setTimeout(resolve, ms)
          );
    }
    async dynamicScriptIncluded(all_script,id_prefix){
        try {
            for (var i = 0; i < all_script.length; i++) {
                var script_object = all_script[i];
                var document_id = id_prefix+"Dynamic_" + i;
                var old_script = document.getElementById(document_id);
                if (old_script === undefined || old_script === null) {
                    if (script_object.type === 1) {
                        var script = document.createElement('script');
                        script.src = script_object.url;
                        script.id = document_id;
                        //script.onreadystatechange = await this.waitReadyState();
                        //script.onload = script.onreadystatechange;
                        document.body.appendChild(script);
                        if(script_object.need_delay!==undefined&&script_object.need_delay!==null&&script_object.need_delay!==""){
                            await this.delayFunction(script_object.need_delay);
                        }
                        
                        

                    } else if (script_object.type === 2) {
                        var link = document.createElement('link');
                        link.type = script_object.type_name;
                        link.rel = script_object.rel;
                        link.href = script_object.url;
                        link.id = document_id;
                        document.head.appendChild(link);
                    }else if (script_object.type === 3) {
                        var link1 = document.getElementById("title-tag");
                        link1.innerText=script_object.url;
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    async constructReferrelURL(session_data){
        var base_url="";
        try {
            if(session_data!==undefined&&session_data.user_id!==undefined&&session_data.user_id!==""){
                base_url=window.location.protocol+"//"+window.location.host+"/login?type=2&ref="+session_data.user_id;
            }
        } catch (error) {
            console.error(error);
        }
        return base_url;
    }

}
export default new HttpClient();
