import React, { Component } from 'react'
import Environment from '../manager/environment';
import HttpClient from '../manager/HttpClient';
import { Dialog } from 'primereact/dialog';
const { default_config } = Environment;

export class SessionHandleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
    this.sessionContinue();
  }

  openSessionExpiredTimeout() {
    try {
      var token_details = window.sessionStorage.getItem("token_details");
      if (this.timeoutFunction) {
        clearTimeout(this.timeoutFunction);
      }
      if (token_details !== undefined && token_details !== "") {
        token_details = JSON.parse(token_details);
        var exp = token_details.exp;
        var expiry_date = new Date();
        expiry_date.setTime(exp * 1000)
        var current_date = new Date();
        var addTimeout = 8;
        if (expiry_date.getTime() > current_date.getTime()) {
          current_date.setMinutes(current_date.getMinutes() + addTimeout);
          if (expiry_date.getTime() <= current_date.getTime() && !this.state.display_pop) {
            this.setState({ display_pop: true });
            this.timeoutFunction = setTimeout(() => this.openSessionExpiredTimeout(), 5);
          } else if (expiry_date.getTime() <= current_date.getTime() && this.state.display_pop) {
            current_date.setMinutes(current_date.getMinutes() - addTimeout);
            if (expiry_date.getTime() < current_date.getTime()) {
              this.sessionClose();
            } else {
              this.timeoutFunction = setTimeout(() => this.openSessionExpiredTimeout(), 5);
            }
          } else {
            this.timeoutFunction = setTimeout(() => this.openSessionExpiredTimeout(), 5);
          }
        } else {
          if (expiry_date.getTime() < current_date.getTime()) {
            this.sessionClose();
          } else {
            this.timeoutFunction = setTimeout(() => this.openSessionExpiredTimeout(), 5);
          }
        }
      } else {
        //this.openSessionExpiredTimeout();
        this.timeoutFunction = setTimeout(() => this.openSessionExpiredTimeout(), 5);
      }
    } catch (error) {
      console.error(error);
    }

  }
  componentWillUnmount() {
    if (this.timeoutFunction) {
      clearTimeout(this.timeoutFunction)
    }
  }
  sessionContinue() {
    if (this.timeoutFunction) {
      clearTimeout(this.timeoutFunction);
    }
    this.setState({ display_pop: false });
    HttpClient.session_validation(default_config).then(url => {
      if (url === undefined || url === "") {
        HttpClient.get_Session_Data({ page_key: 'Session Handling' }).then(session_data => {
          if (session_data !== undefined) {
            this.setState({ session_data: session_data })
            this.openSessionExpiredTimeout();
          }
        });
      } else {
        HttpClient.log_out_option(url);
      }
    });


  }
  sessionClose() {
    HttpClient.remove_user_session().then(session_data => {
      this.sessionContinue();
    });

  }
  session_header(options) {
    return (
      <div>
        <p style={{ width: "88%", marginLeft: "5px", marginRight: "5px" }}>Session Expiring!</p>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Dialog header={this.session_header} visible={this.state.display_pop} style={{ width: "25%" }}>
          <div className='row'>
            <div className='col-md-12'>
              <label style={{ fontWeight: "600", marginTop: "5px" }}>
                This page has been open or idle for a while.
              </label>
              <label style={{ fontWeight: "600", marginTop: "5px",marginBottom:"10px" }}>
                Click continue to presume.
              </label>
              <label style={{marginTop:"10px"}}><span style={{fontWeight:"600",paddingLeft:"5px",paddingRight:"5px"}}>Tip:</span> Save your progress to avoid loosing unsaved data.</label>
            </div>
            <div className='col-md-12' style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
              <button type="button" className="btn btn-primary btn-icon-text" onClick={(e) => { this.sessionContinue() }}>
                <i style={{ marginRight: "5px", marginLeft: "5px" }} className="mdi mdi-check"></i>
                <span style={{ paddingRight: "5px", paddingLeft: "5px" }}>Continue</span>
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default SessionHandleComponent
