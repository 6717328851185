import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"><Trans></Trans> © {new Date().getFullYear()} LEIRA. <a href="https://www.bootstrapdash.com/" target="_blank" rel="noopener noreferrer"> DESIGN & DEVELOPED BY ETERNA</a>. <Trans></Trans>.</span>
        </div>
      </footer>
    );
  }
}

export default Footer;