import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import HttpClient from '../manager/HttpClient';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      need_loader: false,
      page_loaded: false,
      edit_user_object: undefined,
      side_bar_list: []
    }
  }
  componentDidMount() {
    try {
      this.onRouteChanged();
      const body = document.querySelector('body');
      document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
        el.addEventListener('mouseover', function () {
          if (body.classList.contains('sidebar-icon-only')) {
            el.classList.add('hover-open');
          }
        });
        el.addEventListener('mouseout', function () {
          if (body.classList.contains('sidebar-icon-only')) {
            // el.classList.remove('hover-open');
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  load_page_data(session_data, id) {
    try {
      var edit_user_object = undefined;
      if (session_data.user_details !== undefined) {
        var role_privilege_group = session_data.role_privilege_group;
        var side_bar_list = session_data.side_bar_list;
        if (side_bar_list === undefined) {
          side_bar_list = [];
        }
        edit_user_object = session_data.user_details;
        this.setState({ page_loaded: true, session_data: session_data, edit_user_object: edit_user_object, role_privilege_group: role_privilege_group, side_bar_list: side_bar_list });
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleMenuState(sidebar_main_object, side_bar_list) {
    try {
      if (side_bar_list === undefined) {
        side_bar_list = [];
      }
      for (var i = 0; i < side_bar_list.length; i++) {
        var row_object = side_bar_list[i];
        if (row_object.id === sidebar_main_object.id) {
          if (row_object.menuOpen) {
            row_object.menuOpen = false;
          } else {
            row_object.menuOpen = true;
          }
        } else {
          row_object.menuOpen = false;
        }
      }
      this.setState({ sidebar_main_object: sidebar_main_object });
    } catch (error) {
      console.error(error);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  load_session_data() {
    try {
      HttpClient.get_Session_Data({page_key:"Session Handling"}).then(session_data => {
        if (session_data !== undefined) {
          this.load_page_data(session_data, session_data.user_id);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  onRouteChanged() {
    try {
      if (this.state.edit_user_object !== undefined) {
        this.setState({ edit_user_object: this.state.edit_user_object });
      }
    } catch (error) {
      console.error(error);
    }
  }
  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
  render() {
    if (this.state.edit_user_object === undefined ||this.state.edit_user_object === null || this.state.edit_user_object.uniquekey === undefined || this.state.edit_user_object.uniquekey === "") {
      this.load_session_data();
      return (<nav className="sidebar sidebar-offcanvas" id="sidebar"></nav>);
    }
    var user_type=this.state.edit_user_object.user_type;
    var side_bar_list = this.state.side_bar_list;
    var default_image_logo=require('../../assets/images/faces-clipart/pic-1.png');
    if(this.state.edit_user_object.user_profile_logo!==undefined&&this.state.edit_user_object.user_profile_logo!==null&&this.state.edit_user_object.user_profile_logo!==""){
      default_image_logo=this.state.edit_user_object.user_profile_logo;
    }
    if (side_bar_list === undefined) {
      side_bar_list = [];
    }
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top" style={{ background: "black", borderRight: "1px solid white", borderBottom: "1px solid white" }}>
          <a className="sidebar-brand brand-logo" href="index.html"><img src={"/public/assets/images/company_logo/logo.png"} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini" href="index.html"><img src={"/public/assets/images/company_logo/small_logo.png"} alt="logo" /></a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img className="img-xs rounded-circle " src={default_image_logo} alt="profile" />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal text-wrap-class" style={{ maxWidth: "120px" }}><Trans>{this.state.edit_user_object.first_name + " " + this.state.edit_user_object.last_name + ""}</Trans></h5>
                  <span className='text-wrap-class' style={{ maxWidth: "120px" }}><Trans>{this.state.edit_user_object.role_details.name}</Trans></span>
                </div>
              </div>
              <Dropdown >
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret" style={{display:(user_type===2)?"none":""}}>
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  <a href="/user/profile" className="dropdown-item preview-item" onClick={evt => { }}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Profile</Trans></p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="!#" className="dropdown-item preview-item" onClick={evt => { HttpClient.log_out_option({}); evt.preventDefault() }}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Log Out</Trans></p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          {
            side_bar_list.map((sidebar_main_object, sidebar_main_index) => {
              var items = sidebar_main_object.items;
              var need_sub_menu = true;
              if (items === undefined || items.length === 0) {
                items = [];
                need_sub_menu = false;
              }
              if (!need_sub_menu) {
                return (
                  <li className={this.isPathActive(sidebar_main_object.path) ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                    <Link className="nav-link" to={sidebar_main_object.path} onClick={(e) => this.toggleMenuState(sidebar_main_object, side_bar_list)}>
                      <span className="menu-icon"><i className={sidebar_main_object.icon}></i></span>
                      <span className="menu-title">{sidebar_main_object.name}</span>
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li className={this.isPathActive(sidebar_main_object.path) ? 'nav-item menu-items active' : 'nav-item menu-items'} style={{ display: true ? "" : "none" }}>
                    <div className={sidebar_main_object.menuOpen ? 'nav-link menu-expanded' : 'nav-link '} onClick={(e) => this.toggleMenuState(sidebar_main_object, side_bar_list)} data-toggle="collapse">
                      <span className="menu-icon"><i className={sidebar_main_object.icon}></i></span>
                      <span className="menu-title"><Trans>{sidebar_main_object.name}</Trans></span><i className="menu-arrow"></i>
                    </div>
                    <Collapse in={sidebar_main_object.menuOpen}>
                      <ul className="nav flex-column sub-menu">
                        {
                          items.map((sidebar_sub_object, sidebar_sub_index) => {
                            return (
                              <li className={this.isPathActive(sidebar_sub_object.path) ? 'nav-item active' : 'nav-item'} >
                                <Link className={this.isPathActive(sidebar_sub_object.path) ? 'nav-link active' : 'nav-link'} to={sidebar_sub_object.path}>
                                  <span className="menu-icon" style={{ borderRadius: "0" }}><i className={sidebar_sub_object.icon}></i></span>
                                  <span className="menu-title"><Trans>{sidebar_sub_object.name}</Trans></span>
                                </Link>
                              </li>
                            );
                          })
                        }
                      </ul>
                    </Collapse>
                  </li>
                );
              }

            })
          }
        </ul>
      </nav>
    );
  }
  
  
}

export default withRouter(Sidebar);