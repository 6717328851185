import React, { Component } from 'react'

export class Spinner extends Component {
  componentDidMount() {
    try {
      var full_page_wrapper=document.getElementsByClassName("full-page-wrapper");
      if(full_page_wrapper!==undefined &&full_page_wrapper!==null && full_page_wrapper.length>0){
        full_page_wrapper[0].style.paddingLeft="0px";
        full_page_wrapper[0].style.paddingRight="0px";
      }
    } catch (error) {
        console.error(error);
    }
  }
  componentWillUnmount() {
    try {
      var full_page_wrapper=document.getElementsByClassName("full-page-wrapper");
      if(full_page_wrapper!==undefined &&full_page_wrapper!==null && full_page_wrapper.length>0){
        full_page_wrapper[0].style.paddingLeft="unset";
        full_page_wrapper[0].style.paddingRight="unset";
      }
    } catch (error) {
        console.error(error);
    }
  }
  render() {
    return (
      <div>
        <div className="spinner-wrapper">
          <div className="donut"></div>
        </div>
      </div>
    )
  }
}

export default Spinner
