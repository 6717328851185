import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
      resources:{
          en: {
              translations: {
                "Overview dashboard": "Overview dashboard",
              }
          },
          ar: {
              translations: {
              }
          }
      },
      fallbackLng: "en",
      debug: false,
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: false, // we use content as keys
      interpolation: {
        escapeValue: false
      }
  });

  export default i18n;