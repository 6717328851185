export default {
    default_config:{
        back_end_base_url: "http://ec2-3-29-2-122.me-central-1.compute.amazonaws.com:9090",
        ui_date_format:"YYYY-MM-DD",
        ui_dateandtime_format:"YYYY-MM-DD HH:mm:ss",
        back_end_date_format:"YYYY-MM-DD",
        back_end_date_time_format:"YYYY-MM-DD HH:mm:ss",
        toast_config:{hideAfter: 5, position: 'top-right', heading: 'Attention'},
        data_table_paginator:{
            currentPageReportTemplate:"Showing {first} to {last} of {totalRecords}",
            paginatorTemplate:"CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" 
        },
        holding_domains:[
            "leiraholding.com",
            "www.leiraholding.com",
            "localhost"
        ],
        template_base_url:"",
        api_details:{
            register_user:"/admin/register_user",
            login_user:"/admin/login_user",
            site_visitor:"/admin/add_site_visit",
            token_validation:"/admin/token_validation",
            get_roles:"/admin/get_roles",
            add_roles:"/admin/add_role",
            get_group:"/admin/get_group",
            add_group:"/admin/add_group",
            get_faqs:"/admin/get_faq",
            add_faq:"/admin/add_faq",
            get_faq_by_id:"/admin/get_faq_by_id",
            get_role_by_id:"/admin/get_role_by_id",
            get_roles_form_data:"/admin/get_role_form",
            get_users:"/admin/get_users",
            add_user:"/admin/add_user",
            get_KYC:"/admin/get_kyc",
            add_kyc:"/admin/kyc-action",
            get_user_by_id:"/admin/get_user_by_id",
            get_system_logs:"/admin/getSystemLogs",
            add_subscribe:"/admin/landing-page/subscribe",
            file_upload:"/upload",
            admin_ico_dashboard:"/admin/ico-dashboard",
            investor_ico_dashboard:"/admin/ico-dashboard-investor",
            get_profile_info:"/admin/profile-user",
            admin_ico_transaction:"/admin/report/transaction",
            admin_ico_stage:"/admin/report/ico",
            admin_ico_financial:"/admin/report/financial",
            admin_ico_customer:"/admin/report/customer",
            notification_setting:"/admin/notification-settings",
            get_notification_setting:"/admin/get-notification-settings",
            notification_template_action:"/admin/notification-templates",
            get_notification_template:"/admin/get-notification-templates",
            ico:{
                get_landing_page_details:"/admin/crypto/get_landing_page_details",
                get_stage_form_details:"/admin/crypto/get_stage_form_details",
                get_stages:"/admin/crypto/get_stages",
                get_stage_by_id:"/admin/crypto/get_stage_by_id",
                stage_action:"/admin/crypto/stage_action",
                currency_rate_action:"/admin/crypto/currency_rate_action",
                get_currency_rate_list:"/admin/crypto/get_currency_rate_list",
                get_buy_token_form_details:"/admin/crypto/get_buy_token_form_details",
                update_wallet_form_details:"/admin/crypto/update_wallet_form_details",
                wallet_action:"/admin/crypto/wallet_action",
                get_buy_token_validate:"/admin/crypto/buy_token_validate",
                buy_token_bank_transfer:"/admin/crypto/buy_tokens_bank_transfer",
                get_token_approvals:"/admin/crypto/get_token_approval_list",
                get_transaction_details:"/admin/crypto/transaction_details",
                approve_tokens:"/admin/crypto/token_approval_admin",
                customer_wallet_dashboard:"/admin/crypto/customer/wallet-dashboard",
            }
        }
    }
    
};