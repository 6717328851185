import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import HttpClient from '../manager/HttpClient';
import SessionHandleComponent from './SessionHandleComponent';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      need_loader: false,
      page_loaded: false,
      edit_user_object: undefined
    }
  }
  componentDidMount() {
    try {
      HttpClient.get_Session_Data({page_key:"Session Handling"}).then(session_data => {
        if (session_data !== undefined&&session_data!==null) {
          this.load_page_data(session_data, session_data.user_id);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  load_page_data(session_data, id) {
    try {
      var edit_user_object = undefined;
      if(session_data.user_details!==undefined){
        edit_user_object=session_data.user_details;
        this.setState({ page_loaded: true, session_data: session_data, edit_user_object: edit_user_object });
      }
    } catch (error) {
      console.error(error);
    }
  }
  toggleOffcanvas() {
    try {
      document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    } catch (error) {
      console.error(error);
    }
  }
  toggleRightSidebar() {
    try {
      document.querySelector('.right-sidebar').classList.toggle('open');
    } catch (error) {
      console.error(error);
    }
    
  }
  profileRedirection(){
    try {
      if(this.state.edit_user_object!==undefined && this.state.edit_user_object.user_type!==undefined){
        var redirection_url="";
        if(this.state.edit_user_object.user_type===2){
          redirection_url="/investor/profile";
        }
        if(redirection_url!==undefined&&redirection_url!==""){
          window.location.href=redirection_url;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  log_out_option(){
    try {
      if(this.state.edit_user_object!==undefined && this.state.edit_user_object.user_type!==undefined){
        var redirection_url="";
        if(this.state.edit_user_object.user_type===2){
          redirection_url="/login";
        }else if(this.state.edit_user_object.user_type===1){
          redirection_url="/admin/login";
        }
        if(redirection_url!==undefined&&redirection_url!==""){
          HttpClient.log_out_option(redirection_url);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  render () {
    if(this.state.edit_user_object===undefined ||this.state.edit_user_object===null||this.state.edit_user_object.uniquekey===undefined||this.state.edit_user_object.uniquekey===""){
      return (<nav className="navbar p-0 fixed-top d-flex flex-row"></nav>);
    }
    var default_image_logo=require('../../assets/images/faces-clipart/pic-1.png');
    if(this.state.edit_user_object.user_profile_logo!==undefined&&this.state.edit_user_object.user_profile_logo!==null&&this.state.edit_user_object.user_profile_logo!==""){
      default_image_logo=this.state.edit_user_object.user_profile_logo;
    }
    return (
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={"/public/assets/images/company_logo/small_logo.png"} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <Dropdown  as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <img className="img-xs rounded-circle" src={default_image_logo} alt="profile" />
                  <p className="mb-0 d-none d-sm-block navbar-profile-name text-wrap-class" style={{maxWidth:"120px"}}><Trans>{this.state.edit_user_object.first_name+" "+this.state.edit_user_object.last_name+""}</Trans></p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <Dropdown.Item href="#!" onClick={evt =>{this.profileRedirection();evt.preventDefault()}} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1"><Trans>Profile</Trans></p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>{this.log_out_option({});evt.preventDefault()}}  className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1"><Trans>Log Out</Trans></p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
        <SessionHandleComponent></SessionHandleComponent>
      </nav>
    );
  }
}

export default Navbar;
