import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PageSpinner from './shared/Spinner';
import Environment from './manager/environment';

const { default_config } = Environment;
const HoldingHomePage=lazy(() => import('./landing_pages/HoldingHomePage'));
const HoldingAboutPage=lazy(() => import('./landing_pages/HoldingAboutPage'));
const HoldingContactUSPage=lazy(() => import('./landing_pages/HoldingContactUSPage'));
const HoldingProjectPage=lazy(() => import('./landing_pages/HoldingProjectPage'));

const CryptoLandingPage = lazy(() => import('./landing_pages/CryptoLandingPage'));
const CryptoDashboard = lazy(() => import('./admin_portal/crypto/dashboard/CryptoDashboard'));
const FinanceReport = lazy(() => import('./admin_portal/crypto/reports/FinanceReport'));
const TransactionReport=lazy(() => import('./admin_portal/crypto/reports/TransactionReport'));
const ICOReport=lazy(() => import('./admin_portal/crypto/reports/ICOReport'));
const CustomerReport=lazy(() => import('./admin_portal/crypto/reports/CustomerReport'));
const RoleList = lazy(() => import('./admin_portal/roles/RoleList'));
const RoleElement = lazy(() => import('./admin_portal/roles/RoleElement'));
const UserList = lazy(() => import('./admin_portal/users/UserList'));
const CustomerList = lazy(() => import('./admin_portal/users/CustomerList'));
const UserElement = lazy(() => import('./admin_portal/users/UserElement'));
const NotifcationList = lazy(() => import('./admin_portal/notifcation/NotifcationList'));

const StageElement = lazy(() => import('./admin_portal/crypto/ico/StageElement'));
const Stages = lazy(() => import('./admin_portal/crypto/ico/Stages'));
const TokenApprovals=lazy(() => import('./admin_portal/crypto/ico/TokenApprovals'));
const CurrencyConversion = lazy(() => import('./admin_portal/crypto/ico/CurrencyConversion'));
const FAQElement = lazy(() => import('./admin_portal/faq/FAQElement'));
const FAQList = lazy(() => import('./admin_portal/faq/FAQList'));
const SystemLogList = lazy(() => import('./admin_portal/audit/SystemLogList'));
const KYCList = lazy(() => import('./admin_portal/kyc/KYCList'));

const LoginPage = lazy(() => import('./login_pages/Login'));

const InvestorProfile = lazy(() => import('./login_pages/InvestorProfile'));
const InvestorDashboard = lazy(() => import('./customer_portal/dashboard/InvestorDashboard'));
const CryptoMarketPlace = lazy(() => import('./customer_portal/crypto/marketplace/CryptoMarketPlace'));
const MyWallets = lazy(() => import('./customer_portal/crypto/marketplace/MyWallets'));


class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_details:{company_details:undefined}
    }
  }
  render () {
    var LandingPageComponent=CryptoLandingPage;
    var hostname=window.location.hostname;

    if(default_config.holding_domains.includes(hostname)){
      LandingPageComponent=HoldingHomePage;
    }
    return (
      <Suspense fallback={<PageSpinner/>}>
        <Switch>
          <Route path="/home" exact component={ LandingPageComponent } />
          
          <Route path="/login" exact component={ LoginPage } />
          <Route path="/admin/login" exact component={ LoginPage } />
          <Route path="/admin/users/roles" exact component={ RoleList } />
          <Route path="/admin/users/roles/add-roles/:id" exact component={ RoleElement } />
          <Route path="/admin/users/admin" exact component={ UserList } />
          <Route path="/admin/users/customer" exact component={ CustomerList } />
          <Route path="/admin/users/admin/add-user/:id" exact component={ UserElement } />
          <Route path="/admin/users/customer/add-user/:id" exact component={ UserElement } />
          <Route path="/admin/support/faq/list" exact component={ FAQList } />
          <Route path="/admin/support/faq/list/add-faq/:id" exact component={ FAQElement } />
          <Route path="/admin/audit/system-logs" exact component={ SystemLogList } />
          <Route path="/admin/settings/notifications" exact component={ NotifcationList } />

          
          <Route path="/admin/kyc/list" exact component={ KYCList } />

          <Route path="/admin/crypto/dashboard" exact component={ CryptoDashboard } />
          <Route path="/admin/crypto/ico/stages" exact component={ Stages } />
          <Route path="/admin/crypto/ico/stages/add-stage/:id" exact component={ StageElement } />
          <Route path="/admin/crypto/ico/currency-conversion" exact component={ CurrencyConversion } />
          <Route path="/admin/crypto/ico/token-approvals" exact component={ TokenApprovals } />
          
          <Route path="/admin/reports/crypto/financial" exact component={ FinanceReport } />
          <Route path="/admin/reports/crypto/transaction" exact component={ TransactionReport } />
          <Route path="/admin/reports/crypto/ico" exact component={ ICOReport } />
          <Route path="/admin/reports/crypto/customer" exact component={ CustomerReport } />
          
          <Route path="/investor/dashboard" exact component={ InvestorDashboard } />
          <Route path="/investor/market-place" exact component={ CryptoMarketPlace } />
          <Route path="/investor/my-wallets" exact component={ MyWallets } />
          <Route path="/investor/profile" exact component={ InvestorProfile } />
          
          <Route path="/holding/about-us" exact component={ HoldingAboutPage } />
          <Route path="/holding/contact-us" exact component={ HoldingContactUSPage } />
          <Route path="/holding/projects/:name" exact component={ HoldingProjectPage } />
          <Route path="/holding/business-lines/:name" exact component={ HoldingProjectPage } />
          <Route path="/holding/home" exact component={ HoldingHomePage } />
          
          
          <Redirect to="/home" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;