import React from 'react';
import { withRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import HttpClient from "./manager/HttpClient";
import Environment from './manager/environment';

//import './App.scss';
import '../assets/styles/progressBar.css';
import '../assets/styles/prime_react.css';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
const all_holding_script = [
  { name: "", url: "/public/assets/img/holding/leira_logocircle.png", type: 2, rel: "icon" },
  { name: "", url: "Leira Holding", type: 3 },
];
const all_Crypto_script = [
  { name: "", url: "/public/assets/images/company_logo/small_logo.png", type: 2, rel: "icon" },
  { name: "", url: "Leira Coin", type: 3 },
];
const { default_config } = Environment;
class App extends React.Component  {
  
  constructor(props) {
    super(props);
    this.state = {
      page_details:{company_details:undefined},
      isCustomer_portal:false
    }
  }
  componentDidMount() {
    try {
      var all_script = all_Crypto_script;
      var hostname=window.location.hostname;
      if(default_config.holding_domains.includes(hostname)){
        all_script = all_holding_script;
      }
      HttpClient.dynamicScriptIncluded(all_script,"main_");
      this.onRouteChanged();
    } catch (error) {
      console.error(error);
    }

  }
  

  componentDidUpdate(prevProps) {
    try {
      if (this.props.location !== prevProps.location) {
        this.onRouteChanged();
      }
    } catch (error) {
      console.error(error);
    }
  }
  onRouteChanged() {
    try {
      const { i18n } = this.props;
      const body = document.querySelector('body');
      var pathname=this.props.location.pathname;
      if(pathname===undefined){
        pathname="";
      }
      const full_pageDynamic=['/holding/projects/','/holding/business-lines/'];
      const fullPageLayoutRoutes = [
        '/user-pages/login-1', 
        '/user-pages/login-2', 
        '/user-pages/register-1', 
        '/user-pages/register-2', 
        '/user-pages/lockscreen', 
        '/error-pages/error-404', 
        '/error-pages/error-500', 
        '/general-pages/landing-page',
        '/customer-portal/sign-up',
        '/admin/login',
        '/login',
        '/home',
        '/holding/about-us',
        '/holding/contact-us',
        '/holding/home',
        '/holding/projects',
        '/holding/business-lines/'
      ];
      const customerPageLayoutRoutes = [
        '/customer-portal/ico-dashboard',
        '/customer-portal/wallet/buy-tokens'
      ];
      if(pathname === '/layout/RtlLayout') {
        body.classList.add('rtl');
        i18n.changeLanguage('ar');
      } else {
        body.classList.remove('rtl')
        i18n.changeLanguage('en');
      }
      window.scrollTo(0, 0);
      var isCustomer_portal=false;
      var need_appScss=true;
      for ( let i = 0; i < customerPageLayoutRoutes.length; i++ ) {
        if (pathname === customerPageLayoutRoutes[i]) {
          isCustomer_portal=true;
          break;
        }
      }
      var isDynamic_path=false;
      var dynamic_path="";
      for ( let i = 0; i < full_pageDynamic.length; i++ ) {
        if(pathname.includes(full_pageDynamic[i])){
          isDynamic_path=true;
          dynamic_path=pathname;
          break;
        }
      }
      for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
        if (pathname === fullPageLayoutRoutes[i]||(isDynamic_path&&pathname===dynamic_path)) {
          this.setState({isFullPageLayout: true,isCustomer_portal:isCustomer_portal});
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          need_appScss=false;
          break;
        } else {
          
          this.setState({isFullPageLayout: false,isCustomer_portal:isCustomer_portal});
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
        }
      }
      if(need_appScss){
        require('./App.scss');
      }  
    } catch (error) {
      console.error(error);
    }
  }
  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    if(this.state.isCustomer_portal){
     // sidebarComponent=<Customer_Sidebar></Customer_Sidebar>
    }
    return (
      <div className="container-scroller">
        { sidebarComponent }
        <div className="container-fluid page-body-wrapper">
          { navbarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(App));
